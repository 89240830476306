var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm,"title":"Basic Information"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Registered Name","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Registered Name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"placeholder":"Startup Registered Name","type":"text"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Brand Name","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"Brand Name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"brand","state":errors.length > 0 ? false:null,"placeholder":"Startup Brand Name","type":"text"},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CIN","label-for":"cin"}},[_c('validation-provider',{attrs:{"name":"CIN","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cin","state":errors.length > 0 ? false:null,"placeholder":"Startup Registration Number","type":"text"},model:{value:(_vm.cin),callback:function ($$v) {_vm.cin=$$v},expression:"cin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"DIPP","label-for":"dipp"}},[_c('validation-provider',{attrs:{"name":"DIPP","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dipp","state":errors.length > 0 ? false:null,"placeholder":"Startup DIPP Number","type":"text"},model:{value:(_vm.dipp),callback:function ($$v) {_vm.dipp=$$v},expression:"dipp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Website","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false:null,"placeholder":"Startup Website URL","type":"text"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormInfo,"title":"Founders' Details"}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Founders' Details ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('founders-info')],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormAddress,"title":"Business Information"}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Business Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Startup Stage","label-for":"startupStage"}},[_c('validation-provider',{attrs:{"name":"Startup Stage","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"startupStage","options":['Ideation',
                                 'Business modelling/Prototyping',
                                 'Minimum Viable Product(MVP)',
                                 'Market traction',
                                 'Product Introduction',
                                 'Revenue generation',
                                 'Scaling'],"label":"Startup Stage","placeholder":"Select/Enter Startup Stage","push-tags":"","taggable":""},model:{value:(_vm.startupStage),callback:function ($$v) {_vm.startupStage=$$v},expression:"startupStage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Startup Industry","label-for":"industry"}},[_c('validation-provider',{attrs:{"name":"Startup Industry","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"industry","options":[],"label":"Startup Industry","placeholder":"Select/Enter Startup Industry","push-tags":"","taggable":""},model:{value:(_vm.industry),callback:function ($$v) {_vm.industry=$$v},expression:"industry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Products or Services","label-for":"products"}},[_c('validation-provider',{attrs:{"name":"Products or Services","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"products","options":['Ideation',
                                 'Business modelling/Prototyping',
                                 'Minimum Viable Product(MVP)',
                                 'Market traction',
                                 'Product Introduction',
                                 'Revenue generation',
                                 'Scaling'],"label":"Products/Services","placeholder":"Select/Enter Products or Services","multiple":"","push-tags":"","taggable":""},model:{value:(_vm.products),callback:function ($$v) {_vm.products=$$v},expression:"products"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Business Plan","label-for":"images"}},[_c('validation-provider',{attrs:{"name":"Business Plan","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"bPlan"},model:{value:(_vm.bPlan),callback:function ($$v) {_vm.bPlan=$$v},expression:"bPlan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Reveunue Projection","label-for":"revenueDocs"}},[_c('validation-provider',{attrs:{"name":"Reveunue Projection","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"revenueDocs"},model:{value:(_vm.revenueDocs),callback:function ($$v) {_vm.revenueDocs=$$v},expression:"revenueDocs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Incorporation documents","label-for":"incDocs"}},[_c('validation-provider',{attrs:{"name":"Incorporation documents","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"incDocs"},model:{value:(_vm.incDocs),callback:function ($$v) {_vm.incDocs=$$v},expression:"incDocs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('h6',{staticClass:"mt-25"},[_vm._v(" Additional Documents ")])]),_c('b-col',[_c('hr')])],1)],1),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.otherDocs),function(doc,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Document Title","label-for":("Doctype-" + (index + 1))}},[_c('b-form-input',{attrs:{"id":("Doctype-" + (index + 1)),"type":"text","placeholder":"Document Title"},model:{value:(doc.title),callback:function ($$v) {_vm.$set(doc, "title", $$v)},expression:"doc.title"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Upload Document","label-for":("doc-" + (index + 1))}},[_c('b-form-file',{attrs:{"id":("doc-" + (index + 1)),"accept":"image/jpeg, image/png, image/gif"},model:{value:(doc.file),callback:function ($$v) {_vm.$set(doc, "file", $$v)},expression:"doc.file"}})],1)],1),_c('b-col',{attrs:{"sm":"auto"}},[(_vm.otherDocs.length > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.otherDocs.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1):_vm._e(),(index === _vm.otherDocs.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2 mt-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.otherDocs.push({
                        title: '',
                        file: '',
                      })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Doc")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }